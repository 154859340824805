// import './components/aria-menu';

var site = {
	rate : 500,
	prefix : '/erskine'
};

var md = new MobileDetect(window.navigator.userAgent);

(function($){

	if( document.querySelector('body').classList.contains('single-webinar') ){
		$(".main-article").fitVids();
	}

	if( !md.mobile() ){

		// TOGGLE PERSONNEL DROP DOWN

		$('#menu-item-42 a').on('click',function(){
			
			// if( $('#menu-contact').is(':visible') ){
			// 	$('#menu-contact').slideUp(site.rate,function(){
			// 		// $('#menu-personnel').slideDown(site.rate);
			// 		$('body').removeClass('nav-active');
			// 	});
			// }else{
			// 	// $('#menu-personnel').slideDown(site.rate);
			// 	$('body').removeClass('nav-active');
			// }
			$('body').toggleClass('nav-active');
			$('body').removeClass('contact-active');
			return false;
		});

		// TOGGLE CONTACT DROPDOWN

		$('#menu-item-46 a').click(function(){
			$('body').toggleClass('contact-active');
			$('body').removeClass('nav-active');
			// if( $('#menu-personnel').is(':visible') ){
			// 	$('#menu-personnel').slideUp(site.rate,function(){
			// 		$('#menu-contact').slideDown(site.rate);
			// 	});
			// }else{
			// 	$('#menu-contact').slideDown(site.rate);
			// }

			return false;
		});

	}else{

		$('#menu-item-42 a').click(function(){
			window.location.href = site.prefix + "/barristers/";
		});

		// $('#menu-item-43 a').click(function(){
		// 	window.location.href = site.prefix + "/staff-list/";
		// });

		$('#menu-item-46 a').click(function(){
			window.location.href = site.prefix + "/find-us/";
		});

	}

	// $(window).scroll(function(){
	// 	$('#menu-contact,#menu-personnel').slideUp(site.rate);
	// });

	// PRACTICE AREAS SCROLL

	function gotoPA(){
		var hash = window.location.hash.substr(3);
		
		switch(hash){
			case "practice-areas":
				// $.scrollTo("#practice-areas",750,{ offset : -80 });
				$('html, body').animate({
					scrollTop: $("#practice-areas").offset().top -80
				}, 500);
				window.location.hash = "";
				// $('#menu-contact,#menu-personnel').slideUp(site.rate);
				$('body').removeClass('nav-active');
				$('body').removeClass('contact-active');
			break;
		}
	}

	if( $('body').hasClass('home') ){

		$(window).on('load',function(){
			var hash = window.location.hash.substr(3);
			if( hash === "practice-areas" ){
				gotoPA();
			}
		});

	}

	$(window).on('hashchange', function(e) {
		gotoPA();
	});

	// CLOSE MEGA MENU

	$('.mega-menu .close-link').on('click',function(){
		// $(this).closest('.mega-menu').slideUp(site.rate);
		$('body').removeClass('nav-active');
		$('body').removeClass('contact-active');
		return false;
	});

	// STAFF ACCORDIONS

	$('.accordion-header').click(function(){
		var $wrapper = $(this).parent();
		$(this).toggleClass('toggled');
		$wrapper.find('.accordion-drawer').slideToggle(site.rate);
	});

	$('.accordion-drawer .close-link-2 a').click(function(){
		$(this).closest('.accordion-drawer').slideToggle(site.rate);
		$(this).closest('.accordion-wrapper').find('.accordion-header').toggleClass('toggled');
		return false;
	});

	// MEGA MENU HOVER

	if( !md.mobile() ){

		$('.staff-link').hover(function(){
			var person = {
				name : $(this).data('name'),
				img : $(this).data('img'),
				gdpr : {
					train: $(this).data('gdpr-train'),
					cert: $(this).data('gdpr-cert')
				}
			};
			var $block = $(this).closest('.mega-menu__inner');
			// console.log(person);
			if( $(this).data('silk') || $(this).data('call') ){
				person.silk = true;
				person.silkDate = $(this).data('silk');
				person.callDate = $(this).data('call');
				person.solicitor = $(this).data('solicitor');
				// console.log(person);
				$block.find('.menu-block__preview .person-silk').show();
				$block.find('.menu-block__preview .person-admin').hide();
				$block.find('.menu-block__preview .person-date__call').html( "Call: " + person.callDate );
				if( person.silkDate ){
					$block.find('.menu-block__preview .person-date__silk').html( " | Silk: " + person.silkDate );
				}else{
					$block.find('.menu-block__preview .person-date__silk').html( "" );
				}
				if( person.solicitor !== "" ){
					$block.find('.menu-block__preview .person-date__solicitor').html( "Solicitor: " + person.solicitor );
				}else{
					$block.find('.menu-block__preview .person-date__solicitor').html( "" );
				}
				$block.find('.menu-block__preview .person-admin__tel').html( "" );
				$block.find('.menu-block__preview .person-admin__email').html( "" );
			}else{
				person.telNum = $(this).data('tel');
				person.email = $(this).data('email');
				$block.find('.menu-block__preview .person-silk').hide();
				$block.find('.menu-block__preview .person-admin').show();
				$block.find('.menu-block__preview .person-date__call').html( "" );
				$block.find('.menu-block__preview .person-date__silk').html( "" );
				$block.find('.menu-block__preview .person-date__solicitor').html( "" );
				$block.find('.menu-block__preview .person-admin__tel').html( "" + person.telNum );
				$block.find('.menu-block__preview .person-admin__email').html( "<br>" + person.email );
			}
			$block.find('.menu-block__preview .person-title').html( person.name );
			
			if( person.gdpr.train ){
				$block.find('.menu-block__preview .gdpr-train').addClass('active');
			}else{
				$block.find('.menu-block__preview .gdpr-train').removeClass('active');
			}

			if( person.gdpr.cert ){
				$block.find('.menu-block__preview .gdpr-cert').addClass('active');
			}else{
				$block.find('.menu-block__preview .gdpr-cert').removeClass('active');
			}

			if( person.img ){
				$block.find('.menu-block__preview .person-img').attr('src',person.img).show();
			}else{
				$block.find('.menu-block__preview .person-img').hide();
			}
			$block.find('.menu-block__preview').addClass('active');
		});

		$('.mega-menu .mega-menu__block ul').hover(function(){},function(){
			$('.menu-block__preview').removeClass('active');
		});

	}else{

		$('.mobile-toggle').click(function(){
			$(this).toggleClass('active');
			$('body').toggleClass('drop-active');
			$('.main-header__nav').slideToggle(250);
		});

	}

	$('.barristers-button').click(function(){
		var target = $(this).attr('href');
		$(target).slideToggle(site.rate);
		$(this).toggleClass('active');
		return false;
	});

	$('#practice-areas a').click(function(){
		var $this = $(this);
		var target = $(this).attr('href');
		if( $(this).hasClass('active') ){
			$this.removeClass('active');
			$(target).slideUp(site.rate);
		}else{
			$('#practice-areas a').removeClass('active');
			$this.addClass('active');
			$('.practice-area').slideUp(site.rate);
			$(target).slideToggle(site.rate);
		}
		
		return false;
	});

	$('.mobile-block__header').click(function(){

		var $this = $(this);
		// var target = $(this).attr('href');
		if( $this.hasClass('active') ){
			$this.removeClass('active');
			$this.next().slideUp(site.rate);
		}else{
			$('.mobile-block__header h2').removeClass('active');
			$this.addClass('active');
			$('.practice-area').slideUp(site.rate);
			$this.next().slideToggle(site.rate);
		}
		
		return false;
	});

	$('.close-alert').click(function(){
		$('body').removeClass('show-banner');
		Cookies.set('erskine-hide-banner', 1, { expires: 7 })
	});

	// if( !Cookies.get('erskine-hide-banner') ){
	// 	$('body').addClass('show-banner');
	// }
	
	if( $('.vimeo-player').length ){

		const player = new Plyr('.vimeo-player', {
			controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
		});

	}

	var elem = document.querySelector('.carousel');

	if( elem ){
		var flkty = new Flickity( elem, {
		// options
			autoPlay: 5000,
			draggable: false,
			pageDots: true,
			prevNextButtons: false,
			imagesLoaded: true,
			fade: true,
			setGallerySize: false
		});
	}

	var headroom  = new Headroom( document.querySelector(".main-header__wrapper"), {  offset : 100 } );
	headroom.init();

})(jQuery);